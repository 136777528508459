.about-page .body-text {
  padding-top: 1em;
}

.about-page .event-header {
  min-height: auto;
}

.about-locations p {
  margin: 0;
}

@media (min-height: 720px) {
  .about-page .body-text {
    padding-top: 2em;
  }  
}
