.event-header {
  position: relative;
  min-height: 200px;
}

.event-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.event-overlay::before {
  display: inline-block;
  height: 100%;
  vertical-align: bottom;
  content: "";
}

.event-overlay-content {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  vertical-align: bottom;
  background: transparent url("../images/event-overlay-content.png") repeat-x 0 0;
  background-size: contain;
}

.event-artist {
  font-size: 1rem;
  text-transform: uppercase;
}

.event-title {
  margin-bottom: 0.5em;
}

.event-dates-item-list {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  margin-top: 3em;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.event-dates-item {
  list-style: none outside none;
}

.event-dates-item + .event-dates-item {
  margin-top: 1rem;
}

.event-dates-item-cell {
  margin: 0;
}

.event-dates-item-location {
  font-weight: 400;
}

.event-dates-item-location,
.event-dates-item-date {
  font-size: 1rem;
  text-transform: uppercase;
}

.event-dates-separator,
.event-dates-item-hours,
.event-dates-item-type {
  font-size: 1rem;
}

.event-language {
  margin: 2em 0;
  font-size: 0.875rem;
}

.event-credits {
  padding: 0;
  margin: 2rem 0;
  font-size: 0.875rem;

  li {
    list-style: none outside none;
  }
}

.event-tickets,
.event-links {
  padding: 1rem;
  margin-top: 2rem;
  font-size: 1rem;
  text-align: center;
  border-top: 1px solid;
  border-bottom: 1px solid;

  ul {
    padding: 0;
  }

  li {
    margin: 1em 0;
    list-style: none outside none;
  }
}

.event-tickets + .event-links {
  margin-top: 0;
  border-top: 0;
}

.event-links-title {
  font-size: 1rem;
  text-transform: uppercase;
}

@include mq(720px) {
  .event-header {
    min-height: 400px;
  }

  .event-artist,
  .event-tickets,
  .event-links-title,
  .event-dates-item-location,
  .event-dates-item-date,
  .event-dates-separator {
    font-size: 1.3125rem;
  }

  .event-dates-item-hours,
  .event-dates-item-type,
  .event-dates-item-remarks {
    font-size: 1.125rem;
  }

  .event-language,
  .event-credits {
    font-size: 0.9375rem;
  }

  .event-dates-item-list {
    display: table;
  }

  .event-dates-item {
    display: table-row;
  }

  .event-dates-item-cell {
    display: table-cell;
    padding: 0.5rem;
  }

  .event-dates-item-type {
    margin-left: 0.5em;
  }
}

@include mq(1020px) {
  .event-artist,
  .event-tickets,
  .event-links-title,
  .event-dates-item-location,
  .event-dates-item-date,
  .event-dates-separator {
    font-size: 1.5rem;
  }

  .event-dates-item-hours,
  .event-dates-item-type,
  .event-dates-item-remarks {
    font-size: 1.3125rem;
  }

  .event-language,
  .event-credits {
    font-size: 1rem;
  }
}

@include mq(1360px) {
  .event-title {
    font-size: 4rem;
  }

  .event-artist,
  .event-tickets,
  .event-links-title,
  .event-dates-item-location,
  .event-dates-item-date,
  .event-dates-separator {
    font-size: 2rem;
  }

  .event-dates-item-hours,
  .event-dates-item-type,
  .event-dates-item-remarks {
    font-size: 1.5rem;
  }

  .event-language,
  .event-credits {
    font-size: 1.125rem;
  }
}
