.header-bar {
  padding: $margin-m;
  border-bottom: 1px solid;
}

.header-bar-logo {
  width: auto;
  height: 3.125rem;
}

.header-bar-title {
  position: absolute;
  margin: 0;
  clip: rect(1px, 1px, 1px, 1px);
  font-size: 1rem;
  font-weight: normal;
  text-transform: uppercase;
}

.menu-language-list {
  white-space: nowrap;
}

.menu-language-item {
  display: inline-block;
}

.menu-language-item::before {
  content: " | ";
}

.menu-language-item:first-child::before {
  content: "";
}

.menu-trigger {
  position: absolute;
  top: 0;
  right: map-get($gutters, XS);
  display: block;
  padding-left: 1rem;
  margin: 0.5em 0;
  text-transform: uppercase;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' enable-background='new 0 0 10 10'%3E%3Cstyle type='text/css'%3E.st0%7Bfill:%23FFFFFF;%7D%3C/style%3E%3Cpath class='st0' d='M0 0h10v2h-10zM0 8h10v2h-10zM0 4h10v2h-10z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 0.625em 0.625em;
}

.menu-small .menu-wrapper {
  display: none;
}

.menu-open {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  display: table;
  width: 100%;
  height: 100%;
  margin: 0;

  .menu-trigger {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 11' enable-background='new 0 0 11 11'%3E%3Cstyle type='text/css'%3E.st0%7Bfill:%232a0845;%7D%3C/style%3E%3Cpath class='st0' d='M1.399-.015l9.617 9.617-1.414 1.414-9.617-9.617zM11.015 1.399l-9.617 9.617-1.414-1.414 9.617-9.617z'/%3E%3C/svg%3E");
    background-size: 0.6875em 0.6875em;
  }

  .menu-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .menu-block {
    margin: 0 map-get($gutters, XS) 1em;
    font-size: 1.25em;
    line-height: 2;
    text-align: center;
  }

  .menu-site-link {
    font-size: 1em;
  }
}

@include mq(1020px) {
  .header-bar-container {
    display: flex;
    justify-content: space-between;

    > * {
      display: flex;
      align-items: center;
    }
  }

  .header-bar-title {
    position: inherit;
    clip: auto;
  }

  .header-bar .menu-list {
    margin: 0;
  }

  .menu-main-item {
    display: inline-block;
    margin: 0 1em;
  }

}
