/* === MIXINS === */

@mixin mq($bp, $q: min-width) {
  @media only screen and (#{$q}: $bp) {
    @content;
  }
}

@mixin visually-hidden() {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  border: 0 !important;
}

/* === ANIMATION === */

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  animation: 500ms ease-out 500ms 1 forwards fadeIn;
}

.fade-out {
  animation: 200ms ease-in 0s 1 forwards fadeOut;
}

/* === HELPER CLASSES === */

.hidden {
  display: none;
}

.inline {
  display: inline;
}

.inline-center {
  display: inline-block;
  vertical-align: middle;
}

.visually-hidden,
.frozen {
  @include visually-hidden();
}
