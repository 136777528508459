.programme {
//  overflow: auto;
}

.programme-item-wrapper-border {
  outline: 1px solid $white;
  outline-offset: -1px;
}

.no-touch .programme-item:hover {
  background-color: $gold;

  .programme-item-thumbnail {
    visibility: hidden;
  }
}

.programme-item {
  position: relative;
  text-decoration: none;
}

.programme-item-text {
  margin: 0.5rem;
}

.programme-poster-wrapper {
  padding-bottom: 125%;
  background: $purple url("../images/MCA-campaign-S.png") no-repeat 50% 50%;
  background-size: cover;
}

.programme-poster-image {
  display: block;
  width: auto;
  max-width: 90%;
  height: auto;
  max-height: 80vh;
  box-sizing: border-box;
  padding: 6vh 2vw 10vh;
  margin: 0 auto;
}

.programme-item-thumbnail {
  display: block;
  width: 100%;
  height: auto;
}

.programme-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent url(../images/event-overlay-content.png) repeat-x 0 0;
}

.programme-item-overlay::before {
  display: inline-block;
  height: 100%;
  margin-right: -0.25em; /* Adjusts for spacing */
  vertical-align: bottom;
  content: "";
}

.programme-item-overlay-content {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  vertical-align: bottom;

  * {
    margin: 0;
  }
}

.programme-item-artist {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.programme-item-title {
  font-size: 1.25rem;
}

.programme-item-news-title {
  margin: 0.5rem;
  font-size: 0.875rem;
  color: $gold;
  text-transform: uppercase;
}

.programme-item-news-list {
  padding: 0.5rem;
  list-style: none outside none;
}

.programme-item-news .programme-item-title {
  margin: 0;

  + * {
    margin-top: 0;
  }
}

.programme-item-type {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.75rem;
  text-transform: uppercase;
}

@include mq(720px) {
  .programme-poster-wrapper {
    padding-bottom: 80.67%;
    background-image: url("../images/MCA-campaign-L.png");
  }
}

@include mq(1020px) {
  .programme-poster-wrapper {
    padding-bottom: 59.87%;
  }

  .programme-item-artist {
    font-size: 1rem;
  }

  .programme-item-title {
    font-size: 1.5rem;
  }

  .programme-item-news-title {
    font-size: 1rem;
  }
}
