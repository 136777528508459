body > main {
  padding-bottom: $margin-l;
}

body > footer {
  padding: $margin-l $margin-m $margin-s;
}

.main-footer {
  clear: both;
}

.site-credits {
  margin-bottom: 2rem;
  font-size: 0.875rem;
  p {
    margin: 0;
  }
}

.partners-list {
  padding: 0;
  margin: 0;
}

.partners-list-item {
  display: inline-block;
  width: 7rem;
  height: 3rem;
  margin: 0 0.5rem 1rem 0;
}

.partners-logo-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
}

@include mq(720px) {
  body > main {
    padding-bottom: $margin-l * 2;
  }
}
