.grid {
  max-width: 2100px;
  margin: 0.75rem;
}

.grid-item {
  display: block;
  margin: 0 0 0.75rem;
}

@media (min-width: map-get($breakpoints, S)) {
  .grid {
    display: grid;
    gap: 0.75rem;
    grid-template-columns: 1fr 1fr;
  }

  .grid-item {
    margin: 0;
  }

  .grid-item-double {
    grid-column: 0 / span 2;
    grid-row: auto / span 3;
  }
}

@media (min-width: map-get($breakpoints, M)) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: map-get($breakpoints, XL)) {
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: map-get($breakpoints, XXL)) {
  .grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: map-get($breakpoints, XXXL)) {
  .grid {
    grid-template-columns: repeat(6, 1fr);
  }
}
