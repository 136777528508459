/* 
 * Colours
 */
$green:       #408862;
$dark-green:  #336d4e;
$orange:      #d46317;
$dark-orange: #c74c00;
$purple:      #5f3584;
$dark-purple: #2a0845;
$purple-grey: #d8cae1;
$gold:        #d3ab00;
$white:       #fff;
$black:       #161514;

$bg: $dark-purple;

body {
  color: $purple-grey;
  background: $bg;
}

h2,
h3,
h4,
h5,
h6,
strong,
a {
  color: $white;
}

.body-text a {
  border-color: $purple;
}

.theme-border-light {
  border-color: $white;
}

.theme-border-secondary {
  border-color: $purple-grey;
}

.theme-bg-darker {
  background-color: $dark-purple;
}

.theme-bg-white {
  background-color: $white;
}

.theme-bg-secondary {
  background-color: $purple;
}

.theme-text-secondary {
  color: $purple-grey;
}

.menu-open {
  * {
    color: $purple;
  }

  .menu-trigger path {
    fill: $purple;
  }
}

body > footer {
  border-top: 1px solid $white;
}
