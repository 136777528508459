$gutters: (
  XS:  2.75%,
  S:   2%,
  M:   1.75%,
  L:   1.6%,
  XL:  1.6%,
  XXL: 1.25%
);

$columns: (
  XS:  94.5%,  // 1
  S:   48%,    // 2
  M:   31.58%, // 3
  L:   23%,    // 4
  XL:  23%,    // 4
  XXL: 18.5%   // 5
);

$breakpoints: (
  S:     480px,
  M:     720px,
  L:    1020px,
  XL:   1360px,
  XXL:  1740px
);

/* wrapper with column children */
.grid-legacy {
  margin: map-get($gutters, XS);
  overflow: auto;

  @include mq(map-get($breakpoints, S)) {
    margin: map-get($gutters, S) / 2;
  }
  @include mq(map-get($breakpoints, M)) {
    margin: map-get($gutters, M) / 2;
  }
  @include mq(map-get($breakpoints, L)) {
    margin: map-get($gutters, L) / 2;
  }
  @include mq(map-get($breakpoints, XXL)) {
    margin: map-get($gutters, XXL) / 2;
  }
}
/* wrapper without column child */
.grid-wrapper-no-children {
  width: auto;
  margin: map-get($gutters, XS);

  @include mq(map-get($breakpoints, S)) {
    width: auto;
    margin: map-get($gutters, S);
  }
  @include mq(map-get($breakpoints, M)) {
    width: auto;
    margin: map-get($gutters, M);
  }
  @include mq(map-get($breakpoints, L)) {
    width: auto;
    margin: map-get($gutters, L);
  }
  @include mq(map-get($breakpoints, XXL)) {
    width: auto;
    margin: map-get($gutters, XXL);
  }
}

.grid-legacy .grid-item {
  display: block;
  float: left;
  width: map-get($columns, XS);
  margin: map-get($gutters, XS) / 2;

  @include mq(map-get($breakpoints, S)) {
    width: map-get($columns, S);
    margin: map-get($gutters, S) / 2;
  }
  @include mq(map-get($breakpoints, M)) {
    width: map-get($columns, M);
    margin: map-get($gutters, M) / 2;
  }
  @include mq(map-get($breakpoints, L)) {
    width: map-get($columns, L);
    margin: map-get($gutters, L) / 2;
  }
  @include mq(map-get($breakpoints, XXL)) {
    width: map-get($columns, XXL);
    margin: map-get($gutters, XXL) / 2;
  }
}

.grid-legacy .grid-item-double {
  @include mq(map-get($breakpoints, S)) {
    width: map-get($columns, S) * 2 + map-get($gutters, S);
  }
  @include mq(map-get($breakpoints, M)) {
    width: map-get($columns, M) * 2 + map-get($gutters, M);
  }
  @include mq(map-get($breakpoints, L)) {
    width: map-get($columns, L) * 2 + map-get($gutters, L);
  }
  @include mq(map-get($breakpoints, XXL)) {
    width: map-get($columns, XXL) * 2 + map-get($gutters, XXL);
  }
}

.grid-legacy .grid-item-full-width {
  display: block;
  float: none;
  width: auto;
  margin: map-get($gutters, XS) / 2;

  @include mq(map-get($breakpoints, S)) {
    width: auto;
    margin: map-get($gutters, S) / 2;
  }
  @include mq(map-get($breakpoints, M)) {
    width: auto;
    margin: map-get($gutters, M) / 2;
  }
  @include mq(map-get($breakpoints, L)) {
    width: auto;
    margin: map-get($gutters, L) / 2;
  }
  @include mq(map-get($breakpoints, XXL)) {
    width: auto;
    margin: map-get($gutters, XXL) / 2;
  }
}

.grid-wrapper-max-3 {
  @include mq(map-get($breakpoints, L)) {
    margin-right: map-get($gutters, L) + map-get($columns, L) / 2;
    margin-left: map-get($gutters, L) + map-get($columns, L) / 2;
  }
  @include mq(map-get($breakpoints, XXL)) {
    margin-right: map-get($gutters, XXL) + map-get($columns, XXL) / 2;
    margin-left: map-get($gutters, XXL) + map-get($columns, XXL) / 2;
  }

  .grid-item {
    @include mq(map-get($breakpoints, L)) {
      width: map-get($columns, M);
      margin: map-get($gutters, M) / 2;
    }
    @include mq(map-get($breakpoints, XXL)) {
      width: map-get($columns, M);
      margin: map-get($gutters, M) / 2;
    }
  }

  .grid-item-double {
    @include mq(map-get($breakpoints, L)) {
      width: map-get($columns, M) * 2 + map-get($gutters, M);
    }
    @include mq(map-get($breakpoints, XXL)) {
      width: map-get($columns, M) * 2 + map-get($gutters, M);
    }
  }

  .grid-item-full-width {
    @include mq(map-get($breakpoints, L)) {
      width: auto;
      margin: map-get($gutters, M) / 2;
    }
    @include mq(map-get($breakpoints, XXL)) {
      width: auto;
      margin: map-get($gutters, M) / 2;
    }
  }

}
