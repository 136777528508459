$trade: trade-gothic-next-condensed, arial-narrow, sans-serif;

html {
  font-size: 16px;
}

body {
  font-family: $trade;
}

h1,
h2,
h3,
h4 {
  margin: 1em 0;
  font-weight: 800;
}

h1 {
  font-size: 2.074em;
  @include mq(720px) { font-size: 3.157em; }
  @include mq(1020px) { font-size: 5.063em; }
}

h2 {
  font-size: 1.728em;
  @include mq(720px) { font-size: 2.369em; }
  @include mq(1020px) { font-size: 3.3375em; }
}

h3 {
  font-size: 1.44em;
  @include mq(720px) { font-size: 1.777em; }
  @include mq(1020px) { font-size: 2.25em; }
}

h4 {
  font-size: 1.2em;
  @include mq(720px) { font-size: 1.333em; }
  @include mq(1020px) { font-size: 1.5em; }
}

h5,
h6 {
  margin: 1.333em 0 1em;
  font-size: 1em;
}

h5 {
  font-weight: 400;
}

.header-bar-title {
  font-weight: 400;
  text-transform: uppercase;
}

strong,
.is-active-link {
  font-weight: 600;
}

a {
  text-decoration: none;
}

.body-text a {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.weight-normal {
  font-weight: 400;
}

.weight-heavy {
  font-weight: 800;
}

.text-centered {
  text-align: center;
}

.body-text,
.menu-main-item-link {
  font-size: 1rem;
  line-height: 1.5;

  @include mq(720px) {
    font-size: 1.125rem;
  }

  @include mq(1020px) {
    font-size: 1.3125rem;
  }

  @include mq(1360px) {
    font-size: 1.5rem;
  }
}
