.calendar-day-wrapper {
  margin-bottom: 4rem;
}

.calendar-date {
  font-size: 1.5rem;
  text-align: center;
}

.calendar-day-list {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  border-top: 1px solid;
  border-bottom: 1px solid;

  li {
    list-style: none outside none;
  }

  li + li {
    margin-top: 1rem;
  }
}

.calendar-item {
  display: block;
}

.calendar-item-cell {
  margin: 0;
}

.calendar-item-artist {
  margin: 0;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.calendar-item-title {
  margin: 0;
  font-size: 1.3125rem;
}

.calendar-item-type {
  font-size: 0.875rem;
  text-transform: uppercase;
}

.calendar-item-location {
  line-height: 1.6;
  text-transform: uppercase;
}

@include mq(720px) {
  .calendar-day-list {
    li {
      display: table;
      width: 100%;
    }

    li + li {
      margin-top: 0;
    }
  }

  .calendar-item {
    display: table-row;
  }

  .calendar-item-cell {
    display: table-cell;
    padding: 0.5rem;
  }

  .calendar-item-cell:first-child,
  .calendar-item-cell:last-child {
    width: 20%;
  }

  .calendar-ongoing {
    .event-dates-item-date,
    .event-dates-separator {
      font-size: 1.3125rem;
    }
  }
}
